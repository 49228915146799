import { VehicleContactRole } from '../../types/vehicle-contact';
import { sleep, hasFlag } from '../../utils';

const createGeoJSONCircle = (center, radiusInKm, points) => {
  if (!points) {
    points = 64;
  }

  var coords = {
    latitude: center[1],
    longitude: center[0],
  };

  var km = radiusInKm;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret],
          },
        },
      ],
    },
  };
};

class MapService {
  _map;
  _pointMarkers = new Set();
  _vehicleMarkers = new Map();

  constructor() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_TOKEN;
  }

  initMap({ mapElement, theme }) {
    return new Promise(async (resolve, reject) => {
      while (!window.hasOwnProperty('mapboxgl')) await sleep(100);

      this._map = new mapboxgl.Map({
        container: mapElement,
        style: theme === 'dark' ? 'mapbox://styles/mapbox/navigation-night-v1' : 'mapbox://styles/mapbox/navigation-day-v1',
        center: [-99.7740464, 38.4493288],
        zoom: 4,
      });

      this._map.on('load', () => {
        resolve();
      });
    });
  }

  createPointMarker(position, radius) {
    if (!this._map) {
      return;
    }

    this.clearPointMarkers();

    const pointMarker = new mapboxgl.Marker({ color: 'red' }).setLngLat([position.lng, position.lat]).addTo(this._map);

    this._pointMarkers.add({ remove: () => pointMarker.remove(), update: () => pointMarker.addTo(this._map) });

    const drawCircle = () => {
      this._map.addSource('point-marker', createGeoJSONCircle([position.lng, position.lat], (radius | 0) * 1.609));

      this._map.addLayer({
        id: 'point-radius-fill',
        source: 'point-marker',
        type: 'fill',
        paint: {
          'fill-color': '#ff0000',
          'fill-opacity': 0.2,
        },
      });

      this._map.addLayer({
        id: 'point-radius-stroke',
        source: 'point-marker',
        type: 'line',
        paint: {
          'line-width': 2,
          'line-color': '#ff0000',
          'line-opacity': 0.4,
        },
      });
    };

    drawCircle();
    this._pointMarkers.add({
      remove: () => {
        if (this._map.getLayer('point-radius-stroke')) {
          this._map.removeLayer('point-radius-stroke');
        }

        if (this._map.getLayer('point-radius-fill')) {
          this._map.removeLayer('point-radius-fill');
        }

        if (this._map.getSource('point-marker')) {
          this._map.removeSource('point-marker');
        }
      },
      update: () => drawCircle(),
    });
  }

  clearPointMarkers() {
    this._pointMarkers.forEach(marker => marker.remove());
    this._pointMarkers.clear();
  }

  createVehicleMarker(vehicle, vehicleTypeName, vehicleTypeColor) {
    if (!this._map) {
      return;
    }

    this.removeVehicleMarker(vehicle);

    if (!vehicle?.location) {
      return;
    }

    const position = { lat: vehicle.location.lat, lng: vehicle.location.lng };

    const markerText =
      `Unit: ${vehicle.unit}<br>` +
      `Owner: ${vehicle.contacts.find(i => hasFlag(i.roles, VehicleContactRole.OWNER))?.person?.name}<br>` +
      `Type: ${vehicleTypeName}<br>` +
      `Dimensions: ${vehicle.length}/${vehicle.width}/${vehicle.height} (${vehicle.doorWidth}/${vehicle.doorHeight})<br>` +
      `Capacity: ${vehicle.capacity}<br>` +
      `Notes: ${vehicle.notes || '-'}`;

    const el = document.createElement('div');
    el.id = `marker_${vehicle.id}`;
    el.style.width = '1rem';
    el.style.height = '1rem';
    el.style.borderRadius = '50%';
    el.style.backgroundColor = vehicleTypeColor;

    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(markerText);

    const marker = new mapboxgl.Marker(el)
      .setLngLat([position.lng, position.lat])
      .setPopup(popup)
      .addTo(this._map);

    this._vehicleMarkers.set(vehicle.id, marker);
  }

  removeVehicleMarker(vehicle) {
    const marker = this._vehicleMarkers.get(vehicle.id);
    marker?.remove();
    this._vehicleMarkers.delete(vehicle.id);
  }

  clearVehicleMarkers() {
    this._vehicleMarkers.forEach(marker => marker.remove());
    this._vehicleMarkers.clear();
  }

  updateMarkers() {
    this._pointMarkers.forEach(marker => marker.update());
    this._vehicleMarkers.forEach(marker => marker.addTo(this._map));
  }
}

export const mapService = new MapService();
